import React, { Fragment } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

import Root from "./pages/Root";
import ErrorPage from "./pages/Error";
import VerifyMobileRoot from "./pages/verifyMobile/VerifyMobileRoot";
import VerifyMobilePage from "./pages/verifyMobile/VerifyMobillePage";
import KendrasRoot from "./pages/kendras/KendrasRoot";
import KendrasPage from "./pages/kendras/KendrasPage";

import { action as verifyMobileAction } from "./pages/verifyMobile/VerifyMobileRoot";
import { loader as kendrasLoader } from "./pages/kendras/KendrasRoot";
import { action as kendraAction } from "./pages/kendras/KendrasRoot";

const router = createBrowserRouter([
  {
    id: "root",
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        id: "verify-mobile-email",
        path: "verify-mobile-email",
        element: <VerifyMobileRoot />,
        children: [
          {
            index: true,
            element: <VerifyMobilePage />,
            action: verifyMobileAction,
          },
        ],
      },
      {
        id: "kendras",
        path: "kendras",
        element: <KendrasRoot />,
        children: [
          {
            id: "kendra",
            path: ":kendraId",
            element: <KendrasPage />,
            loader: kendrasLoader,
            action: kendraAction,
          },
        ],
      },
    ],
  },
]);

function App() {
  return (
    <Fragment>
      <RouterProvider router={router} />
    </Fragment>
  );
}

export default App;

import React from "react";
import { Outlet } from "react-router-dom";
import { Grid2 } from "@mui/material";

import api from "../../utils/api";
import getEndpoint from "../../utils/utils";

function VerifyMobileRoot() {
  return (
    <Grid2
      container
      justifyContent={"center"}
      sx={{
        background: "#1A242E",
        height: "100vh",
        width: "100vw",
      }}
    >
      <Outlet />
    </Grid2>
  );
}

export default VerifyMobileRoot;

export async function action({ request }) {
  const endpoint = getEndpoint(request.url.split("?")[0]);
  const queryParams = new URLSearchParams(request.url.split("?")[1]);

  const requestBody = {
    token: queryParams.get("token"),
    mobileOrEmail: queryParams.get("mobile-or-email"),
  };
  console.log("requestBody in kendra action", requestBody);

  try {
    const response = await api.post(endpoint, requestBody);
    return response; // Return the response to React Router
  } catch (error) {
    console.error("Error while making the API request", error);
    return {
      error: `Verification for the ${requestBody.mobileOrEmail} failed due to a server error. Please try again later.`,
    };
  }
}

import React from "react";
import { useNavigation, useLoaderData } from "react-router-dom";

import KendraDetail from "../../components/kendra/KendraDetail";

function KendrasPage() {
  const loaderData = useLoaderData();
  const navigation = useNavigation();
  const isSubmitting = navigation.state === "submitting";

  return (
    <>
      {isSubmitting && !loaderData && <div>Loading...</div>}
      {!isSubmitting && loaderData && <KendraDetail kendra={loaderData.data} />}
    </>
  );
}

export default KendrasPage;

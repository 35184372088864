import React, { useState } from "react";
import { useSubmit } from "react-router-dom";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Button,
  Grid2,
  Modal,
  Divider,
} from "@mui/material";
import { v4 as uuidv4 } from "uuid"; // Import uuid to generate unique ids
import { styled } from "@mui/system";

import KendraLocations from "./KendraLocations";

const LocationModal = styled(Modal)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "100vh",
  position: "absolute",
  top: "0%",
  margin: 0,
  padding: 0,
  backdropFilter: "blur(10px)", // Add blur effect to the background
});

const MainBox = styled(Box)({
  backgroundColor: "#1A242E",
  height: "100vh",
  overflowY: "auto",
});

const GrayDivider = styled(Divider)({
  backgroundColor: "#FFFFFF1F",
  borderWidth: "1px",
  borderRadius: "5px",
  marginBottom: "8px",
});

const TransparentCard = styled(Card)({
  backgroundColor: "transparent",
  borderWidth: "1px",
  borderColor: "#FFFFFF1F",
});

const LocationButton = styled(Button)({
  color: "#82DFFF",
  fontSize: "12px",
  borderColor: "#FFFFFF1F",
  width: "100%",
});

const SaveButton = styled(LocationButton)({
  color: "#FFFFFF61",
});

const ButtonIcon = styled(Button)({
  color: "#d1d1d1",
  padding: "0px",
  marginBottom: "8px",
  marginLeft: "10px",
  minWidth: "auto",
});

const HeadingTypography = styled(Typography)({
  color: "#EEEEEE",
});

const PrimaryTypography = styled(HeadingTypography)({
  fontSize: "14px",
  letterSpacing: "0.50px",
  marginBottom: "8px",
});

const SecondaryTypography = styled(Typography)({
  color: "#d1d1d1",
  fontSize: "13px",
  letterSpacing: "0.15px",
});

const FormTitleTypography = styled(SecondaryTypography)({
  color: "#EEEEEE6D",
  fontWeight: 400,
  lineHeight: "20px",
  letterSpacing: "0.25px",
});

const getDayName = (dayNumber) => {
  const days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  return days[dayNumber];
};

function KendraDetail({ kendra }) {
  const [isModalOpen, setIsModalOpen] = useState(false); // Track if modal is open
  const [selectedLocationId, setSelectedLocationId] = useState(null); // Track selected location
  const [savedLocations] = useState(
    kendra.locations ? JSON.parse(JSON.stringify(kendra.locations)) : []
  ); // Deep copy of saved locations
  const [locations, setLocations] = useState(
    kendra.locations ? kendra.locations : []
  ); // Editable locations
  const submit = useSubmit();

  const handleOpenModal = () => {
    setIsModalOpen(true); // Open the modal
  };

  const handleCloseModal = () => {
    setIsModalOpen(false); // Close the modal
  };

  const handleSave = () => {
    const requestData = {
      locations: locations,
    };

    // Trigger submit hook when save button is clicked
    submit(requestData, {
      method: "post",
      encType: "application/json",
    });
  };

  const handleLocationSet = (locationId = null) => {
    if (!locationId) {
      const newLocation = {
        _id: uuidv4(),
        name: `New Location ${locations.length + 1}`,
        address: "",
        lat: null,
        long: null,
      };
      setLocations([...locations, newLocation]);
      locationId = newLocation._id;
    }
    setSelectedLocationId(locationId);
    handleOpenModal();
  };

  const handleDeleteLocation = (_id) => {
    const updatedLocations = locations.filter((loc) => loc._id !== _id);
    setLocations(updatedLocations);
  };

  const region = `${kendra.country}/${kendra.state}/${kendra.district}/${kendra.taluka}`;
  const dayName = getDayName(kendra.weekDay);
  const dateNTime = `${dayName} | ${kendra.time}`;

  return (
    <MainBox>
      {!isModalOpen && (
        <Grid2
          container
          justifySelf={"center"}
          justifyContent={"center"}
          sx={{ width: { xs: "100vw", sm: "500px" }, padding: "24px" }}
          spacing={2}
        >
          <Grid2 size={12} textAlign={{ xs: "left", sm: "center" }}>
            <HeadingTypography variant="h6">
              Manage Kendra Locations
            </HeadingTypography>
          </Grid2>
          <Grid2 size={12} container justifyContent={"center"} spacing={1}>
            <Grid2 size={12} textAlign={"left"}>
              <PrimaryTypography>Kendra Details</PrimaryTypography>
              <TransparentCard variant="outlined">
                <CardContent>
                  <Grid2 container spacing={0.8}>
                    <Grid2 size={6}>
                      <FormTitleTypography>Kendra Name</FormTitleTypography>
                      <SecondaryTypography>
                        {kendra.village}
                      </SecondaryTypography>
                    </Grid2>
                    <Grid2 size={6}>
                      <FormTitleTypography>Kendra Code</FormTitleTypography>
                      <SecondaryTypography>{kendra.code}</SecondaryTypography>
                    </Grid2>
                    <Grid2 size={12}>
                      <FormTitleTypography>Region</FormTitleTypography>
                      <SecondaryTypography>{region}</SecondaryTypography>
                    </Grid2>
                    <Grid2 size={12}>
                      <FormTitleTypography>Date & Time</FormTitleTypography>
                      <SecondaryTypography>{dateNTime}</SecondaryTypography>
                    </Grid2>
                  </Grid2>
                </CardContent>
              </TransparentCard>
            </Grid2>
            <Grid2 size={12}>
              <PrimaryTypography>Responsible Details</PrimaryTypography>
              {kendra.responsibles.map((responsible, index) => (
                <Box key={index}>
                  <GrayDivider />
                  <SecondaryTypography>{responsible.name}</SecondaryTypography>
                  <FormTitleTypography>
                    {responsible.mobile}
                  </FormTitleTypography>
                  <FormTitleTypography>{responsible.email}</FormTitleTypography>
                </Box>
              ))}
            </Grid2>

            <Grid2 size={12}>
              <Box display="flex" flexDirection="row" alignItems="center">
                <PrimaryTypography>Primary Location</PrimaryTypography>
                {locations && locations[0] ? (
                  <ButtonIcon
                    onClick={() => handleLocationSet(locations[0]._id)}
                    startIcon={<i className="material-icons">edit</i>}
                  />
                ) : null}
              </Box>
              {locations && locations[0] ? (
                <TransparentCard variant="outlined">
                  <CardContent>
                    <SecondaryTypography>
                      {locations[0].address}
                    </SecondaryTypography>
                    <SecondaryTypography>
                      {locations[0].lat}, {locations[0].long}
                    </SecondaryTypography>
                  </CardContent>
                </TransparentCard>
              ) : (
                <LocationButton
                  onClick={() => handleLocationSet()}
                  variant="outlined"
                >
                  Set Primary Location
                </LocationButton>
              )}
            </Grid2>
            <Grid2 size={12}>
              <Box display="flex" flexDirection="row" alignItems="center">
                <PrimaryTypography>Seconday Location</PrimaryTypography>
                {locations && locations.length > 1 ? (
                  <>
                    <ButtonIcon
                      onClick={() => handleLocationSet(locations[1]._id)}
                      startIcon={<i className="material-icons">edit</i>}
                    />
                    <PrimaryTypography variant="body2">|</PrimaryTypography>
                    <ButtonIcon
                      onClick={() => handleDeleteLocation(locations[1]._id)}
                      startIcon={<i className="material-icons">delete</i>}
                    />
                  </>
                ) : null}
              </Box>
              {locations && locations.length > 1 ? (
                <TransparentCard variant="outlined">
                  <CardContent>
                    <SecondaryTypography>
                      {locations[1].address}
                    </SecondaryTypography>
                    <SecondaryTypography>
                      {locations[1].lat}, {locations[1].long}
                    </SecondaryTypography>
                  </CardContent>
                </TransparentCard>
              ) : (
                <LocationButton
                  onClick={() => handleLocationSet()}
                  variant="outlined"
                >
                  Set Secondary Location
                </LocationButton>
              )}
            </Grid2>
          </Grid2>
          <Grid2 size={12}>
            <SaveButton onClick={handleSave} variant="contained">
              Save
            </SaveButton>
          </Grid2>
        </Grid2>
      )}

      {/* Modal for editing locations */}
      <LocationModal
        open={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <KendraLocations
            locations={locations}
            savedLocations={savedLocations}
            locationId={selectedLocationId}
            setLocations={setLocations}
            onClose={handleCloseModal}
          />
        </>
      </LocationModal>
    </MainBox>
  );
}

export default KendraDetail;

import React, { Fragment } from "react";
import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";

function Root() {
  return (
    <Fragment>
      {/* Outlet for rendering child routes */}
      <Box
        sx={{
          background: "#1A242E",
          height: "100vh",
          width: "100vw",
        }}
      >
        <Outlet />
      </Box>
    </Fragment>
  );
}

export default Root;

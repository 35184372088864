import React from "react";
import { Outlet } from "react-router-dom";

import api from "../../utils/api";
import getEndpoint from "../../utils/utils";

function KendrasRoot() {
  return (
    <>
      <Outlet />
    </>
  );
}

export default KendrasRoot;

export async function loader({ request }) {
  const endpoint = getEndpoint(request.url.split("?")[0]); // Base endpoint
  const queryParams = new URLSearchParams(request.url.split("?")[1]);
  const token = queryParams.get("token");

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };

  try {
    const response = await api.get(endpoint, { headers });
    return response;
  } catch (error) {
    console.error("Error while making the API request", error);
    return {
      error:
        error.response?.data?.message ||
        "Something went wrong during verification",
    };
  }
}

export async function action({ request, params }) {
  const endpoint = getEndpoint(request.url.split("?")[0]);
  const queryParams = new URLSearchParams(request.url.split("?")[1]);

  const token = queryParams.get("token");

  const data = await request.json(); // This is for JSON requests

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  console.log("requestBody in kendra action", data);

  try {
    const response = await api.post(endpoint + "/locations", data, { headers });
    // Return the response to React Router
    return response;
  } catch (error) {
    console.error("Error while making the API request", error);
    return {
      error:
        error.response?.data?.message ||
        "Something went wrong during verification",
    };
  }
}

import React, { useEffect, useState } from "react";
import {
  useSubmit,
  useNavigation,
  useActionData,
} from "react-router-dom";
import {
  Button,
  CircularProgress,
  Typography,
  Alert,
  Grid2,
} from "@mui/material";

const VerifyMobilePage = () => {
  const submit = useSubmit();
  const [isReload, setIsReload] = useState(true);
  const navigation = useNavigation();
  const actionData = useActionData();

  const isSubmitting = navigation.state === "submitting" || isReload;

  useEffect(() => {
    setIsReload(false);
    // initFlag = false;
    submit(null, { method: "post" });
  }, [submit]);

  // Extract the message and error state from actionData
  const message =
    actionData?.message ||
    (actionData?.error ? actionData.error : "") ||
    "Verification successful!";
  const isError = actionData?.error ? true : false; // Error state

  return (
    <Grid2
      container
      alignContent={"space-between"}
      justifyContent={"center"}
      textAlign={"center"}
      size={{ xs: 12, sm: 6, md: 4 }}
      sx={{
        position: "absolute",
        top: "40%",
        padding: "32px 20px 20px 20px",
        borderRadius: {
          xs: "48px 48px 0px 0px",
          sm: "48px",
        },
        background: "#FFFFFF12",
        height: {
          xs: "60%", // height for small screens
          sm: "300px", // height for medium screens
        },
      }}
    >
      <Grid2 size={12}>
        <Typography variant="h6">Email Verification</Typography>
      </Grid2>

      {
        isSubmitting && !actionData && (
          <Grid2 size={12}>
            <CircularProgress size={24} color="primary" />
          </Grid2>
        ) /* Loader */
      }

      {!isSubmitting && actionData && (
        <Grid2 size={12}>
          <Alert
            severity={isError ? "error" : "success"}
            sx={{ borderRadius: "8px" }}
            icon={
              isError ? (
                <i className="material-icons">error</i>
              ) : (
                <i className="material-icons">check_circle</i>
              )
            }
          >
            <Typography variant="body1">{message}</Typography>
          </Alert>
        </Grid2>
      )}
      <Grid2 size={12}>
        <Button
          variant="contained"
          color="primary"
          // onClick={handleRetry}
          sx={{
            fontSize: "16px",
            borderRadius: "8px",
            visibility: "hidden",
          }}
          startIcon={<i className="material-icons">check_circle</i>}
        >
          Retry
        </Button>
      </Grid2>
    </Grid2>
  );
};

export default VerifyMobilePage;

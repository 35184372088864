import React from "react";
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  Grid2,
  Input,
} from "@mui/material";
import { styled } from "@mui/system";
import {
  Map,
  AdvancedMarker,
  APIProvider,
} from "@vis.gl/react-google-maps";

import { fetchLocation } from "../../utils/utils";

const mapKey = process.env.REACT_APP_GMAP_API;

const ButtonIcon = styled(Button)({
  color: "#d1d1d1",
  padding: "0px",
  marginLeft: "10px",
  minWidth: "auto",
});

const LocationButton = styled(Button)({
  color: "#82DFFF",
  fontSize: "12px",
  borderColor: "#FFFFFF1F",
  width: "100%",
});

const SaveButton = styled(LocationButton)({
  color: "#FFFFFF61",
});

const HeadingTypography = styled(Typography)({
  color: "#EEEEEE",
});

const SecondaryTypography = styled(Typography)({
  color: "#d1d1d1",
  fontSize: "13px",
  letterSpacing: "0.15px",
});

const AddressInputField = styled(Input)({
  "& .MuiInputBase-input": {
    color: "#d1d1d1",
    fontSize: "13px",
    letterSpacing: "0.15px",
    cursor: "text",
    caretColor: "#ffffff",
  },
});

const TransparentCard = styled(Card)({
  backgroundColor: "transparent",
  borderWidth: "1px",
  borderColor: "#FFFFFF1F",
});

function KendraLocations({
  locations,
  savedLocations,
  locationId,
  setLocations,
  onClose,
}) {
  const [currentLocation, setCurrentLocation] = React.useState({
    _id: null,
    name: null,
    address: null,
    lat: null,
    long: null,
  });
  const [isCurrentLocation, setIsCurrentLocation] = React.useState(false);
  const [isEditing, setIsEditing] = React.useState(false);
  const addressInputRef = React.useRef(null);

  const handleIsCurrentLocation = () => {
    fetchLocation((lat, long) => {
      setCurrentLocation({ ...currentLocation, lat, long });
    });
    setIsCurrentLocation(true);
  };

  const handleSavedLocation = () => {
    const setLocation = savedLocations.find(
      (location) => location._id === locationId
    );
    setCurrentLocation(setLocation);
    setIsCurrentLocation(true);
  };

  const handleMapClick = (e) => {
    const lat = e.detail.latLng.lat;
    const long = e.detail.latLng.lng;
    setIsCurrentLocation(false);
    setCurrentLocation({ ...currentLocation, lat, long });
  };

  const handleMarkerDragEnd = (e) => {
    const lat = e.latLng.lat();
    const long = e.latLng.lng();
    setCurrentLocation({ ...currentLocation, lat, long });
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleAddressChange = (e) => {
    const updatedLocations = locations.map((location) => {
      if (location._id === locationId) {
        location.address = e.target.value;
      }
      return location;
    });
    setLocations(updatedLocations);
  };

  const handleConfirmLocation = () => {
    setIsEditing(false);
    const updatedLocations = locations.map((location) => {
      if (location._id === locationId) {
        location.address = currentLocation.address;
        location.lat = currentLocation.lat;
        location.long = currentLocation.long;
      }
      return location;
    });
    setLocations(updatedLocations);
    fetchLocation((lat, long) => {
      setCurrentLocation({
        _id: locationId,
        address: currentLocation.address,
        name: currentLocation.name,
        lat,
        long,
      });
    });
    onClose();
  };

  React.useEffect(() => {
    if (isEditing && addressInputRef.current) {
      const input = addressInputRef.current;
      input.selectionStart = input.textContent.length;
      input.focus();
    }
  }, [isEditing]);

  React.useEffect(() => {
    const setLocation = locations.find(
      (location) => location._id === locationId
    );
    if (!setLocation.lat || !setLocation.long) {
      fetchLocation((lat, long) => {
        setLocation.lat = lat;
        setLocation.long = long;
        setCurrentLocation(setLocation);
      });
    } else {
      setCurrentLocation(setLocation);
    }
  }, [locationId, locations]);

  return (
    <Grid2
      container
      justifyContent={"center"}
      alignItems={"space-between"}
      alignContent={"space-between"}
      sx={{ width: { xs: "100vw", sm: "500px" } }}
      padding={"24px"}
      spacing={2}
    >
      <Grid2 size={12} container flexDirection={"row"} alignItems={"center"}>
        <ButtonIcon
          onClick={onClose}
          startIcon={<i className="material-icons">arrow_back</i>}
        />
        <HeadingTypography variant="h6">Set Locations</HeadingTypography>
      </Grid2>
      <Grid2 size={12}>
        {currentLocation && currentLocation.lat && currentLocation.long ? (
          <>
            <Box width={"100%"} height="60vh" borderRadius={2} marginBottom={2}>
              <APIProvider apiKey={mapKey}>
                <Map
                  key={locationId}
                  mapId={locationId}
                  defaultCenter={{
                    lat: currentLocation.lat,
                    lng: currentLocation.long,
                  }}
                  center={
                    isCurrentLocation
                      ? {
                          lat: currentLocation.lat,
                          lng: currentLocation.long,
                        }
                      : null
                  }
                  defaultZoom={13}
                  gestureHandling="greedy"
                  disableDefaultUI={true}
                  onClick={(e) => handleMapClick(e)}
                >
                  <AdvancedMarker
                    key={locationId}
                    position={{
                      lat: currentLocation.lat,
                      lng: currentLocation.long,
                    }}
                    draggable={true} // Enable dragging
                    onDragEnd={(e) => handleMarkerDragEnd(e)} // Handle drag end
                  >
                    {/* <Pin
                      background={"#005f69"}
                      glyphColor={"#000"}
                      borderColor={"#000"}
                      scale={1}
                    /> */}
                  </AdvancedMarker>
                </Map>
              </APIProvider>
            </Box>
            <Box>
              <LocationButton
                onClick={handleIsCurrentLocation}
                variant="outlined"
              >
                Current Location
              </LocationButton>
              <LocationButton onClick={handleSavedLocation} variant="outlined">
                Saved Location
              </LocationButton>
            </Box>
            <Box>
              <TransparentCard variant="outlined">
                <CardContent>
                  <Grid2
                    container
                    spacing={!isEditing ? 1.8 : 0}
                    justifyContent={"space-between"}
                  >
                    <Grid2 size={!isEditing ? "grow" : 12}>
                      {!isEditing ? (
                        <SecondaryTypography>
                          {currentLocation.address}
                        </SecondaryTypography>
                      ) : (
                        <AddressInputField
                          inputRef={addressInputRef}
                          value={currentLocation.address}
                          onChange={handleAddressChange}
                          fullWidth
                          multiline
                        />
                      )}
                      <SecondaryTypography>
                        {currentLocation.lat}, {currentLocation.long}
                      </SecondaryTypography>
                    </Grid2>
                    {!isEditing ? (
                      <Grid2 size={1}>
                        <ButtonIcon
                          onClick={handleEditClick}
                          startIcon={<i className="material-icons">edit</i>}
                        />
                      </Grid2>
                    ) : null}
                  </Grid2>
                </CardContent>
              </TransparentCard>
            </Box>
          </>
        ) : (
          <span>Null</span>
        )}
      </Grid2>
      <Grid2 size={12}>
        <SaveButton onClick={handleConfirmLocation} variant="contained">
          Confirm Location
        </SaveButton>
      </Grid2>
    </Grid2>
  );
}

export default KendraLocations;

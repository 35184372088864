import React from "react";

function PageContent({ title, children }) {
  return (
    <div style={{ textAlign: "center", marginTop: "50px" }}>
      <h1>{title}</h1>
      {children}
    </div>
  );
}

export default PageContent;
